import { AxiosResponse } from "axios";
import axios from "../apis/countryStateCityAPI";
import { City } from "../interfaces/City";
import { Country } from "../interfaces/Country";
import { StateProvince } from "../interfaces/StateProvince";

export class CountryStateCityService {

    public async getAllCountries(): Promise<AxiosResponse<Country[], any>> {
        return await axios.get<Country[]>("/countries");
    }

    public async getStatesByCountry(stateCode: string): Promise<AxiosResponse<StateProvince[], any>> {
        return await axios.get<StateProvince[]>(`/countries/${stateCode}/states`);
    }

    public async getCitiesByStateAndCountry(stateCode: string, countryCode: string): Promise<AxiosResponse<City[], any>> {
        return await axios.get<City[]>(`/countries/${countryCode}/states/${stateCode}/cities`);
    }
}