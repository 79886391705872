import { registerRootComponent } from "expo";
import { NativeBaseProvider, extendTheme } from "native-base";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import NotFoundPage from "./src/pages/error/NotFound";
import "./src/i18n/i18n.config";
import Root from "./src/routes/root";
import HomePage from "./src/pages/home/HomePage";
import AboutUsPage from "./src/pages/about-us/AboutUs";
import ContactUsPage from "./src/pages/contact-us/ContactUs";
import AnnouncementPage from "./src/pages/announcement/Announcement";
import PartnershipsPage from "./src/pages/partnerships/Partnerships";
import 'rsuite/dist/rsuite.min.css';
import { Provider } from 'react-redux';
import Store from "./src/store/store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useFonts, Lato_400Regular } from '@expo-google-fonts/lato';
import { BebasNeue_400Regular } from "@expo-google-fonts/bebas-neue";
import TermsOfUsePage from "./src/pages/TermsOfUse/TermsOfUse";
import PrivacyPolicyPage from "./src/pages/privacy-policy/PrivacyPolicy";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <NotFoundPage />,
        children: [
            {
                index: true,
                element: <HomePage />,
            },
            {
                path: "/about-us",
                element: <AboutUsPage />,
            },
            // {
            //     path: "/contact-us",
            //     element: <ContactUsPage />,
            // },
            // {
            //     path: "/announcement",
            //     element: <AnnouncementPage />,
            // },
            {
                path: "/partnerships",
                element: <PartnershipsPage />,
            },
            {
                path: "/terms-of-use",
                element: <TermsOfUsePage />,
            },
            {
                path: "/privacy-policy",
                element: <PrivacyPolicyPage />,
            },
        ],
    },
]);

const Main = () => {
    let [fontsLoaded] = useFonts({
        Lato_400Regular,
        BebasNeue_400Regular
    });
    const theme = extendTheme({
        colors: {
            // Add new color
            iw: {
                black: "#0D0D0D",
                white: "#FFFFFF",
                color1: "#285580",
                color2: "#16B09A",
                color3: "#1893D2",
                color4: "#1C8693",
                color5: "#7CCEF4",
                color6: "#DCEFF8",
                color7: "#002C56",
                cinza1: "#3C3C3C",
                cinza2: "#2C2C2C",
                cinza3: "#BDBDBD",
                cinza4: "#F4F4F4"
            }
        }
    });

    return (
        <Provider store={Store}>
            <NativeBaseProvider theme={theme}>
                <RouterProvider router={router} />
                <ToastContainer autoClose={12000} />
            </NativeBaseProvider>
        </Provider>
    );
};

export default registerRootComponent(Main);