import React, { FunctionComponent } from 'react';
import { IIconProps } from './IconProps';

const AccountGroupIcon: FunctionComponent<IIconProps> = (props) => {
    const { size, color } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 96 59"
            width={size}
            height={size}
            fill={color}
        >
            <path d="M48.0003 0.956543C51.7133 0.956543 55.2743 2.42864 57.8998 5.04898C60.5253 7.66933 62.0003 11.2233 62.0003 14.929C62.0003 18.6347 60.5253 22.1887 57.8998 24.809C55.2743 27.4294 51.7133 28.9015 48.0003 28.9015C44.2873 28.9015 40.7263 27.4294 38.1008 24.809C35.4753 22.1887 34.0003 18.6347 34.0003 14.929C34.0003 11.2233 35.4753 7.66933 38.1008 5.04898C40.7263 2.42864 44.2873 0.956543 48.0003 0.956543ZM20.0003 10.9369C22.2403 10.9369 24.3203 11.5357 26.1203 12.6136C25.5203 18.3223 27.2003 23.9912 30.6403 28.4224C28.6403 32.2549 24.6403 34.8897 20.0003 34.8897C16.8177 34.8897 13.7655 33.6279 11.515 31.3819C9.26459 29.1359 8.00031 26.0896 8.00031 22.9133C8.00031 19.7369 9.26459 16.6907 11.515 14.4447C13.7655 12.1987 16.8177 10.9369 20.0003 10.9369ZM76.0003 10.9369C79.1829 10.9369 82.2352 12.1987 84.4856 14.4447C86.736 16.6907 88.0003 19.7369 88.0003 22.9133C88.0003 26.0896 86.736 29.1359 84.4856 31.3819C82.2352 33.6279 79.1829 34.8897 76.0003 34.8897C71.3603 34.8897 67.3603 32.2549 65.3603 28.4224C68.8478 23.929 70.4666 18.2672 69.8803 12.6136C71.6803 11.5357 73.7603 10.9369 76.0003 10.9369ZM22.0003 51.8562C22.0003 43.5925 33.6403 36.8857 48.0003 36.8857C62.3603 36.8857 74.0003 43.5925 74.0003 51.8562V58.8425H22.0003V51.8562ZM0.000305176 58.8425V52.8543C0.000305176 47.3052 7.5603 42.6344 17.8003 41.2771C15.4403 43.9917 14.0003 47.7444 14.0003 51.8562V58.8425H0.000305176ZM96.0003 58.8425H82.0003V51.8562C82.0003 47.7444 80.5603 43.9917 78.2003 41.2771C88.4403 42.6344 96.0003 47.3052 96.0003 52.8543V58.8425Z" fill="url(#paint0_linear_1_462)" />
            <defs>
                <linearGradient id="paint0_linear_1_462" x1="48.0001" y1="0.765137" x2="48.0001" y2="58.7651" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2B587A" />
                    <stop offset="1" stopColor="#13BA9A" />
                </linearGradient>
            </defs>

        </svg>
    );
};

AccountGroupIcon.defaultProps = {
    size: 18,
    color: "none"
};

export default AccountGroupIcon;