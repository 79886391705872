import React, { FunctionComponent } from 'react';
import { IIconProps } from './IconProps';

const DropdownArrowUpIcon: FunctionComponent<IIconProps> = (props) => {
    const { size, color } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 18 12"
            width={size}
            height={size}
            fill={color}
        >
            <path d="M10.7481 1.45225L17.237 7.66965C18.0273 8.42689 18.2037 9.29334 17.7661 10.269C17.3285 11.2446 16.549 11.7333 15.4276 11.7349L2.57459 11.7349C1.45151 11.7349 0.671181 11.2462 0.233597 10.269C-0.203987 9.29175 -0.0267922 8.4253 0.765185 7.66965L7.25408 1.45225C7.50365 1.21312 7.77402 1.03378 8.06519 0.914211C8.35636 0.794646 8.66833 0.734862 9.00109 0.734862C9.33385 0.734862 9.64582 0.794646 9.93699 0.914212C10.2282 1.03378 10.4985 1.21312 10.7481 1.45225Z" fill="#285580" />
        </svg>
    );
};

DropdownArrowUpIcon.defaultProps = {
    size: 18,
    color: "none"
};

export default DropdownArrowUpIcon;