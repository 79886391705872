import React, { FunctionComponent } from 'react';
import { IIconProps } from './IconProps';

const DegreeHatIcon: FunctionComponent<IIconProps> = (props) => {
    const { size, color } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 96 96"
            width={size}
            height={size}
            fill={color}
        >
            <mask id="mask0_1_454" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="2" y="16" width="89" height="64">
                <path d="M4 34.8L46.044 18L88.088 34.8L46.044 51.6L4 34.8Z" fill="white" stroke="white" strokeWidth="4" strokeLinejoin="round" />
                <path d="M88.088 35.02V53.466M23.112 43.65V68.534C23.112 68.534 32.732 78 46.044 78C59.358 78 68.978 68.534 68.978 68.534V43.65" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            </mask>
            <g mask="url(#mask0_1_454)">
                <path d="M0 0H96V96H0V0Z" fill="url(#paint0_linear_1_454)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_1_454" x1="47.9998" y1="-0.317435" x2="47.9997" y2="95.8717" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2B587A" />
                    <stop offset="1" stopColor="#13BA9A" />
                </linearGradient>
            </defs>
        </svg>
    );
};

DegreeHatIcon.defaultProps = {
    size: 18,
    color: "none"
};

export default DegreeHatIcon;
