import { Box, Heading, Text, useMediaQuery } from "native-base";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./AboutUs.css";
import SectionHeader from "../../components/Section/SectionHeader";
import YouTube, { YouTubeProps } from "react-youtube";

const videoEmbedId: string = "P3JJwuvMOB4";

const AboutUsPage = () => {
    const { t } = useTranslation();
    const [videoId, setVideoId] = useState<string>(videoEmbedId);

    const queries = [{ maxWidth: 1280 }, { orientation: "portrait" }, { maxWidth: 375 }, { maxWidth: 1440 }];
    const [isMobile, isPortrait, isExtraSmall, xxl] = useMediaQuery(queries);

    const youtubeVideoOptions: YouTubeProps['opts'] = {
        height: '100%',
        width: "100%",
        playerVars: {
            autoplay: 0
        }
    };

    const onVideoEnd = () => {
        setVideoId("");
        setVideoId(videoEmbedId);
    };

    return (
        <Box mt="24" alignItems="center" justifyContent="center">
            {isMobile === true ? (
                <img width="100%" src={require("../../assets/images/Topo-mobile.png")} />
            ) : (
                <img width="100%" src={require("../../assets/images/Topo-web.png")} />
            )}

            <div className="iw-about-us-content">
                <Heading
                    marginTop="28px"
                    fontSize={{
                        base: "20",
                        xl: "22"
                    }}
                    fontWeight="400"
                    fontFamily="Lato_400Regular"
                    lineHeight={{ base: 22, xl: 32 }}
                    textTransform={'uppercase'}
                    color="iw.black">{t("welcome")}</Heading>
                <h1 className='iw-title'>INTERCHANGING WORLD</h1>

                <div className="iw-about-us-section">
                    <Text
                        maxWidth="100%"
                        fontSize={{
                            base: "18px",
                            sm: "22px",
                            xl: "28px"
                        }}
                        marginRight={{
                            base: 0,
                            xl: "32px"
                        }}
                        marginTop={{ base: "32px", xl: xxl ? "32px" : "0px" }}
                        lineHeight={{
                            base: "20px",
                            sm: "24px",
                            xl: "33px"
                        }}
                        fontFamily="Lato_400Regular"
                        color="iw.cinza1"
                        fontWeight="400">{t("aboutUsPage.firstParagraph")}</Text>
                    <img id="about-us-rocket-image" src={require("../../assets/images/Foguete.png")} />
                </div>

                <div className="iw-about-us-section">
                    <Box
                        w={{
                            base: "90%",
                            xl: "60%"
                        }}
                        height={{
                            base: isExtraSmall ? isPortrait ? "30vh" : "90vh" : isPortrait ? "21.5vh" : "90vh",
                            sm: isExtraSmall ? isPortrait ? "30vh" : "90vh" : isPortrait ? "35vh" : "90vh",
                            md: isPortrait ? "21.5vh" : "50vh",
                            lg: isPortrait ? "21.5vh" : "50vh",
                            xl: "100%",
                        }}
                        overflow="hidden"
                        marginRight={{
                            base: 0,
                            xl: "32px"
                        }}
                        marginTop={{ base: "32px", xl: 0 }}
                    >
                        <div className="iw-about-us-video-background-image">
                            {videoId !== "" ? (
                                <YouTube
                                    videoId={videoId}
                                    className="iw-youtube-video-container"
                                    iframeClassName="iw-youtube-video"
                                    onEnd={() => onVideoEnd()}
                                    opts={youtubeVideoOptions}
                                    loading="eager"
                                />
                            ) : null}
                        </div>
                    </Box>
                    <Text
                        width="100%"
                        fontSize={{
                            base: "18px",
                            sm: "22px",
                            xl: "28px"
                        }}
                        lineHeight={{
                            base: "20px",
                            sm: "24px",
                            xl: "33px"
                        }}
                        fontFamily="Lato_400Regular"
                        color="iw.cinza1"
                        fontWeight="400">
                        <Trans
                            i18nKey="aboutUsPage.secondParagraph" // optional -> fallbacks to defaults if not provided
                            components={{ bold: <strong /> }}
                        />
                    </Text>
                </div>

                <div className="iw-about-us-section" style={{ flexDirection: "column" }}>
                    <Text
                        maxWidth="100%"
                        fontSize={{
                            base: "18px",
                            sm: "22px",
                            xl: "28px"
                        }}
                        lineHeight={{
                            base: "20px",
                            sm: "24px",
                            xl: "33px"
                        }}
                        fontFamily="Lato_400Regular"
                        color="iw.cinza1"
                        marginBottom="10"
                        fontWeight="400">{t("aboutUsPage.thirdParagraph")}</Text>
                    <Text
                        maxWidth="100%"
                        fontSize={{
                            base: "18px",
                            sm: "22px",
                            xl: "28px"
                        }}
                        lineHeight={{
                            base: "20px",
                            sm: "24px",
                            xl: "33px"
                        }}
                        fontFamily="Lato_400Regular"
                        color="iw.cinza1"
                        marginBottom="10"
                        fontWeight="400">{t("aboutUsPage.fourthParagraph")}</Text>
                    <Text
                        maxWidth="100%"
                        fontSize={{
                            base: "18px",
                            sm: "22px",
                            xl: "28px"
                        }}
                        lineHeight={{
                            base: "20px",
                            sm: "24px",
                            xl: "33px"
                        }}
                        fontFamily="Lato_400Regular"
                        color="iw.cinza1"
                        fontWeight="400">
                        <Trans
                            i18nKey="aboutUsPage.fifthParagraph" // optional -> fallbacks to defaults if not provided
                            components={{ bold: <strong /> }}
                        />
                    </Text>
                </div>

                <div className="iw-about-us-section flex-column">
                    <Box width="100%" alignItems="center" marginBottom={6}>
                        <SectionHeader headerContainerprops={{ className: "justify-center" }} title={t("aboutUsPage.ourMission.title").toString()} />
                    </Box>

                    <Box width="100%" flexDir={{ base: "column", xl: "row" }} alignItems="center">
                        <img id="about-us-portal-image" src={require("../../assets/images/Portal.png")} />
                        <Box marginTop={{ base: "32px", xl: 0 }} marginLeft={{ base: 0, xl: "12" }} flex={1} flexWrap="wrap" flexDir="column">
                            <Text
                                maxWidth={{
                                    base: "100%",
                                    xl: "100%"
                                }}
                                fontSize={{
                                    base: "18px",
                                    sm: "22px",
                                    xl: "28px"
                                }}
                                lineHeight={{
                                    base: "20px",
                                    sm: "24px",
                                    xl: "33px"
                                }}
                                fontFamily="Lato_400Regular"
                                color="iw.cinza1"
                                fontWeight="400">{t("aboutUsPage.ourMission.firstParagraph")}</Text>
                            <Text
                                marginTop="32px"
                                maxWidth={{
                                    base: "100%",
                                    xl: "100%"
                                }}
                                fontSize={{
                                    base: "18px",
                                    sm: "22px",
                                    xl: "28px"
                                }}
                                lineHeight={{
                                    base: "20px",
                                    sm: "24px",
                                    xl: "33px"
                                }}
                                fontFamily="Lato_400Regular"
                                color="iw.cinza1"
                                fontWeight="400">{t("aboutUsPage.ourMission.secondParagraph")}</Text>
                        </Box>
                    </Box>

                    <Box marginTop={{ base: "32px", xl: "64px" }} marginLeft={{ base: 0, xl: "12" }} marginBottom="88px">
                        <Text
                            maxWidth={{
                                base: "100%",
                                xl: "100%"
                            }}
                            fontSize={{
                                base: "18px",
                                sm: "22px",
                                xl: "28px"
                            }}
                            lineHeight={{
                                base: "20px",
                                sm: "24px",
                                xl: "33px"
                            }}
                            fontFamily="Lato_400Regular"
                            color="iw.cinza1"
                            fontWeight="400">{t("aboutUsPage.ourMission.thirdParagraph")}</Text>
                        <Text
                            marginTop="32px"
                            maxWidth={{
                                base: "100%",
                                xl: "100%"
                            }}
                            fontSize={{
                                base: "18px",
                                sm: "22px",
                                xl: "28px"
                            }}
                            lineHeight={{
                                base: "20px",
                                sm: "24px",
                                xl: "33px"
                            }}
                            fontFamily="Lato_400Regular"
                            color="iw.cinza1"
                            fontWeight="400">{t("aboutUsPage.ourMission.fourthParagraph")}</Text>
                    </Box>
                </div>
            </div>
        </Box >
    );
};

export default AboutUsPage;