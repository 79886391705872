import { Box } from "native-base";
import React, { FunctionComponent } from "react";
import "./WhyUsCard.css";

export interface IWhyUsCardProps {
    cardIcon: JSX.Element;
    cardDescription: string;
}

const WhyUsCard: FunctionComponent<IWhyUsCardProps> = (props) => {
    return (
        <div className="why-us-card">
            <div className="why-us-card-header">
                {props.cardIcon}
            </div>
            <div className="why-us-card-body">
                {props.cardDescription}
            </div>
        </div>
    );
};

export default WhyUsCard;