import React, { FunctionComponent } from "react";
import { DrawerItemProps } from "./DrawerItemProps";
import { Link } from "react-router-dom";
import "./DrawerItem.css";

const DrawerItem: FunctionComponent<DrawerItemProps> = (props) => {
    return (
        <Link
            {...props}
            className={`iw-drawer-item ${props.className}`}
        />
    );
};

DrawerItem.defaultProps = {
    to: "/",
    className: ""
};

export default DrawerItem;