import React, { FunctionComponent } from 'react';
import { IIconProps } from './IconProps';

const GoalIcon: FunctionComponent<IIconProps> = (props) => {
    const { size, color } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 90 90"
            width={size}
            height={size}
            fill={color}
        >
            <path d="M76.7081 13.2918H76.7025L86.13 15.1762C86.6363 15.2774 87.0525 15.6487 87.1987 16.1493C87.2726 16.3925 87.2792 16.6512 87.2178 16.8978C87.1564 17.1444 87.0293 17.3698 86.85 17.5499L79.1269 25.2787C78.2029 26.201 76.9506 26.7189 75.645 26.7187H69.2438L53.1562 42.8118C53.4632 43.9707 53.5196 45.1817 53.3218 46.3641C53.124 47.5465 52.6765 48.6731 52.0092 49.669C51.3418 50.6649 50.4699 51.5072 49.4515 52.1396C48.433 52.7721 47.2915 53.1803 46.103 53.337C44.9145 53.4938 43.7062 53.3954 42.5586 53.0486C41.4111 52.7017 40.3506 52.1143 39.4479 51.3254C38.5452 50.5365 37.8209 49.5644 37.3234 48.4737C36.8259 47.383 36.5665 46.1988 36.5625 44.9999C36.5633 43.7056 36.8618 42.4287 37.4347 41.2681C38.0077 40.1074 38.8398 39.0941 39.8668 38.3063C40.8939 37.5185 42.0883 36.9774 43.3577 36.7247C44.6272 36.4721 45.9378 36.5147 47.1881 36.8493L63.2812 20.7506V14.3549C63.2812 13.0499 63.7987 11.7956 64.7212 10.8731L72.45 3.14431C72.6302 2.96504 72.8555 2.83794 73.1022 2.77653C73.3488 2.71512 73.6074 2.7217 73.8506 2.79556C74.3512 2.94181 74.7225 3.35806 74.8237 3.86431L76.7081 13.2918Z" fill="url(#paint0_linear_1_446)" />
            <path d="M11.25 45C11.2558 49.826 12.2965 54.5947 14.3019 58.9843C16.3074 63.3739 19.2309 67.2824 22.8753 70.4461C26.5198 73.6098 30.8003 75.9551 35.4281 77.3238C40.056 78.6925 44.9236 79.0528 49.7026 78.3803C54.4815 77.7079 59.0607 76.0183 63.1312 73.4257C67.2017 70.8331 70.6688 67.3976 73.2986 63.3511C75.9285 59.3046 77.66 54.7411 78.3762 49.9685C79.0925 45.1959 78.7768 40.3252 77.4506 35.685C77.2644 35.1428 77.1905 34.5685 77.2334 33.9969C77.2762 33.4253 77.435 32.8684 77.6999 32.3601C77.9649 31.8518 78.3305 31.4028 78.7745 31.0403C79.2186 30.6778 79.7317 30.4095 80.2828 30.2516C80.8338 30.0938 81.4112 30.0498 81.9799 30.1222C82.5485 30.1947 83.0964 30.3821 83.5902 30.6731C84.0841 30.964 84.5136 31.3525 84.8526 31.8147C85.1915 32.277 85.4329 32.8034 85.5619 33.3618C88.1298 42.3479 87.642 51.9323 84.175 60.6112C80.708 69.2902 74.458 76.5726 66.4055 81.3161C58.3529 86.0596 48.9535 87.9957 39.6818 86.8207C30.4102 85.6457 21.7909 81.426 15.1763 74.8237C8.5688 68.2107 4.34461 59.5906 3.1669 50.3168C1.98918 41.043 3.92464 31.6407 8.66938 23.586C13.4141 15.5313 20.6995 9.28045 29.3816 5.81476C38.0637 2.34908 47.651 1.86485 56.6381 4.4381C57.7082 4.75091 58.611 5.47461 59.1492 6.45102C59.6873 7.42743 59.8171 8.57716 59.5101 9.64896C59.2032 10.7208 58.4844 11.6275 57.5109 12.171C56.5375 12.7144 55.3885 12.8505 54.315 12.5493C49.2895 11.1075 43.9978 10.8526 38.857 11.805C33.7163 12.7573 28.867 14.8907 24.6915 18.0371C20.5159 21.1834 17.1284 25.2567 14.7958 29.9358C12.4632 34.6148 11.2494 39.7717 11.25 45Z" fill="url(#paint1_linear_1_446)" />
            <path d="M28.125 45C28.1253 48.0746 28.9651 51.0907 30.5537 53.7231C32.1423 56.3554 34.4194 58.5041 37.1395 59.9374C39.8595 61.3707 42.9193 62.0341 45.9887 61.8561C49.0581 61.6781 52.0207 60.6655 54.5569 58.9275C57.0926 57.1851 59.1037 54.7826 60.3727 51.9799C61.6418 49.1771 62.1204 46.0807 61.7569 43.0256C61.6586 42.2843 61.7596 41.5301 62.0497 40.8408C62.3397 40.1514 62.8082 39.5519 63.407 39.1038C64.0057 38.6557 64.7131 38.3753 65.4563 38.2915C66.1994 38.2077 66.9515 38.3235 67.635 38.6269C68.3184 38.9264 68.9101 39.4018 69.3499 40.0045C69.7896 40.6073 70.0616 41.3159 70.1381 42.0581C70.7493 47.2787 69.7202 52.5601 67.1935 57.1692C64.6669 61.7783 60.768 65.4865 56.0382 67.7792C51.3083 70.0718 45.9821 70.8352 40.7987 69.9633C35.6153 69.0915 30.8318 66.6276 27.1123 62.9137C23.3928 59.1998 20.9216 54.4202 20.0419 49.2381C19.1622 44.056 19.9174 38.7286 22.2029 33.9953C24.4884 29.2619 28.1907 25.3575 32.796 22.8238C37.4012 20.2902 42.681 19.253 47.9025 19.8563C48.4616 19.9081 49.0047 20.0711 49.5 20.3356C49.9953 20.6001 50.4328 20.9608 50.7869 21.3966C51.141 21.8324 51.4045 22.3344 51.5621 22.8734C51.7196 23.4123 51.768 23.9773 51.7043 24.5352C51.6407 25.0931 51.4663 25.6326 51.1914 26.1223C50.9165 26.6119 50.5467 27.0417 50.1035 27.3865C49.6604 27.7313 49.1528 27.9842 48.6107 28.1303C48.0685 28.2765 47.5027 28.3129 46.9463 28.2375C44.5818 27.963 42.1861 28.1918 39.9163 28.9088C37.6466 29.6259 35.5543 30.8151 33.7767 32.3982C31.9992 33.9813 30.5767 35.9225 29.6026 38.0944C28.6285 40.2663 28.125 42.6197 28.125 45Z" fill="url(#paint2_linear_1_446)" />
            <defs>
                <linearGradient id="paint0_linear_1_446" x1="61.9108" y1="2.5673" x2="61.9108" y2="53.3418" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2B587A" />
                    <stop offset="1" stopColor="#13BA9A" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_446" x1="45.0082" y1="2.5292" x2="45.0082" y2="87.0429" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2B587A" />
                    <stop offset="1" stopColor="#13BA9A" />
                </linearGradient>
                <linearGradient id="paint2_linear_1_446" x1="44.9972" y1="19.5216" x2="44.9972" y2="70.2464" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2B587A" />
                    <stop offset="1" stopColor="#13BA9A" />
                </linearGradient>
            </defs>
        </svg>
    );
};

GoalIcon.defaultProps = {
    size: 18,
    color: "none"
};

export default GoalIcon;
