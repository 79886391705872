import React, { FunctionComponent } from "react";
import "./CustomButton.css";

const CustomButton: FunctionComponent<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = (props) => {
    return (
        <button {...props} className={`iw-button ${props.className}`}>
            {props.children}
        </button>
    );
};

CustomButton.defaultProps = {
    className: ""
};

export default CustomButton;