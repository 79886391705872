import React, { FunctionComponent } from "react";
import { TitleProps } from "./TitleProps";
import "./Title.css";

const Title: FunctionComponent<TitleProps> = (props) => {
    return (
        <h3
            {...props}
            className={`iw-basic-title ${props.className}`}
        />
    );
};

Title.defaultProps = {
    className: ""
};

export default Title;