import React, { FunctionComponent } from "react";
import { StepDetailsModalProps } from "./StepDetailsModalProps";
import "./StepDetailsModal.css";

const StepDetailsModal: FunctionComponent<StepDetailsModalProps> = (props) => {
    const { step } = props;
    return (
        <div className="step-details-modal">
            <div className="step-details-title">
                <h1>{step.stepNumber}</h1>
                <h1>{step.stepTitle}</h1>
            </div>
            <div className="step-details-container">
                {step.stepDetailedDescription.map((point) => <p className="detail-item">{point}</p>)}
            </div>
        </div>
    );
};

export default StepDetailsModal;