import React, { FunctionComponent } from 'react';
import { IIconProps } from './IconProps';

const CloseIcon: FunctionComponent<IIconProps> = (props) => {
    const { size, color } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            width={size}
            height={size}
            fill={color}
        >
            <path d="M14 0.666504C21.3733 0.666504 27.3333 6.6265 27.3333 13.9998C27.3333 21.3732 21.3733 27.3332 14 27.3332C6.62666 27.3332 0.666656 21.3732 0.666656 13.9998C0.666656 6.6265 6.62666 0.666504 14 0.666504ZM18.7867 7.33317L14 12.1198L9.21332 7.33317L7.33332 9.21317L12.12 13.9998L7.33332 18.7865L9.21332 20.6665L14 15.8798L18.7867 20.6665L20.6667 18.7865L15.88 13.9998L20.6667 9.21317L18.7867 7.33317Z" fill="#285580" />
        </svg>
    );
};

CloseIcon.defaultProps = {
    size: 40,
    color: "#285580"
};

export default CloseIcon;