import React, { FunctionComponent } from 'react';
import { IIconProps } from './IconProps';

const DropdownArrowDownIcon: FunctionComponent<IIconProps> = (props) => {
    const { size, color } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 10"
            width={size}
            height={size}
            fill={color}
        >
            <path d="M6.60002 8.6L1.40002 3.4C0.766691 2.76667 0.625357 2.042 0.976024 1.226C1.32669 0.41 1.95136 0.00133333 2.85002 0H13.15C14.05 0 14.6754 0.408666 15.026 1.226C15.3767 2.04333 15.2347 2.768 14.6 3.4L9.40002 8.6C9.20002 8.8 8.98336 8.95 8.75002 9.05C8.51669 9.15 8.26669 9.2 8.00002 9.2C7.73336 9.2 7.48336 9.15 7.25002 9.05C7.01669 8.95 6.80002 8.8 6.60002 8.6Z" fill="#3C3C3C" />
        </svg>
    );
};

DropdownArrowDownIcon.defaultProps = {
    size: 18,
    color: "none"
};

export default DropdownArrowDownIcon;
