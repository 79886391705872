import { Box, Heading, Text, useMediaQuery } from "native-base";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import YoutubeEmbed from "../../components/Youtube/YoutubeEmbed";
import SectionHeader from "../../components/Section/SectionHeader";
import { PotentialPartner } from "../../interfaces/PotentialPartner";
import { IwPotentialPartnerService } from "../../services/IwPotentialPartnerService";
import { toast } from 'react-toastify';
import CustomFormControl from "../../components/Form/CustomFormControl";
import CustomLabel from "../../components/Label/CustomLabel";
import CustomSelect from "../../components/Select/CustomSelect";
import { StateProvince } from "../../interfaces/StateProvince";
import CustomInput from "../../components/Input/CustomInput";
import CustomTextArea from "../../components/Input/CustomTextArea";
import CustomButton from "../../components/Button/CustomButton";
import { DefaultTFuncReturn } from "i18next";
import QuotesIcon from "../../components/Icons/QuotesIcon";
import { getObjectKeys } from "../../utility-helper";

const PartnershipsPage = () => {
    const { t } = useTranslation();

    const [email, setEmail] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [organization, setOrganization] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [position, setPosition] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const potentialPartnerService = new IwPotentialPartnerService();

    const handleAddPotentialPartner = () => {
        setLoading(true);
        let subscriber: PotentialPartner = {
            name,
            email,
            position,
            organization,
            message,
        };
        potentialPartnerService.addPotentialPartner(subscriber)
            .then((response) => {
                toast.success(t("partnershipsPage.successes.addPotentialPartner"), { theme: "colored" });
                handleResetFormFields();
                setLoading(false);
            })
            .catch((error) => {
                if (error?.response?.data?.code) {
                    toast.error(t(`partnershipsPage.errors.${error.response.data.code}`), { theme: "colored" });
                }
                else {
                    toast.error(t("partnershipsPage.errors.addPotentialPartner"), { theme: "colored" });
                }
                setLoading(false);
            });
    };

    const handleSubmitEnable = (): boolean => {
        return email && name && position && organization && !loading ? true : false;
    };

    const handleResetFormFields = () => {
        setName("");
        setEmail("");
        setPosition("");
        setOrganization("");
        setMessage("");
    };

    const opportunitites = t('partnershipsPage.opportunities', { returnObjects: true }) as DefaultTFuncReturn[];

    const [isMobile] = useMediaQuery({
        maxWidth: 1280
    });

    return (
        <Box mt="24" alignItems="center" justifyContent="center">
            {isMobile === true ? (
                <img width="100%" src={require("../../assets/images/Partnership-Img-topo-mobile.png")} />
            ) : (
                <img width="100%" src={require("../../assets/images/Partnership-Img-topo.png")} />
            )}

            <div className="iw-contact-us-content" style={{ width: '82%' }}>
                <Box width="100%">
                    <SectionHeader title={t("partnershipsPage.title").toString()} />
                    <Text fontWeight="700" fontFamily="Lato_400Regular" mb="5" color="iw.cinza2" fontSize={{ base: 20, xl: "26" }}>{t("partnershipsPage.firstParagraph")}</Text>
                </Box>

                <Box
                    mt={{
                        base: "5",
                        xl: "0"
                    }}
                    w="100%"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                >
                    {/* <Box display="flex" flexDir={{ base: "column", xl: "row" }} justifyContent={{ base: "center", xl: "space-between" }} width="100%">
                        <CustomFormControl style={{ width: isMobile ? "100%" : "48%" }}>
                            <CustomLabel>{t("partnershipsPage.labels.name")}</CustomLabel>
                            <CustomInput value={name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)} />
                        </CustomFormControl>

                        <CustomFormControl style={{ width: isMobile ? "100%" : "48%" }}>
                            <CustomLabel>{t("partnershipsPage.labels.position")}</CustomLabel>
                            <CustomInput value={position} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPosition(event.target.value)} />
                        </CustomFormControl>
                    </Box>

                    <Box display="flex" flexDir={{ base: "column", xl: "row" }} justifyContent={{ base: "center", xl: "space-between" }} width="100%">
                        <CustomFormControl style={{ width: isMobile ? "100%" : "48%" }}>
                            <CustomLabel>{t("partnershipsPage.labels.email")}</CustomLabel>
                            <CustomInput value={email} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)} />
                        </CustomFormControl>

                        <CustomFormControl style={{ width: isMobile ? "100%" : "48%" }}>
                            <CustomLabel>{t("partnershipsPage.labels.organization")}</CustomLabel>
                            <CustomInput value={organization} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOrganization(event.target.value)} />
                        </CustomFormControl>
                    </Box>

                    <CustomFormControl style={{ marginBottom: "12px" }}>
                        <CustomLabel>{t("partnershipsPage.labels.message")}</CustomLabel>
                        <CustomTextArea value={message} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value)} />
                    </CustomFormControl>

                    <CustomButton
                        style={{ marginBottom: '30px' }}
                        disabled={!handleSubmitEnable()}
                        onClick={handleAddPotentialPartner}
                        className='tertiary'
                    >
                        {loading ? t("formSubmitting") : t("formSubmitButton")}
                    </CustomButton> */}

                    <Box width="100%" marginBottom="32px">
                        <Text
                            maxWidth={{
                                base: "100%",
                                xl: "100%"
                            }}
                            fontSize={{
                                base: "20px",
                                xl: "26px"
                            }}
                            lineHeight="32px"
                            fontFamily="Lato_400Regular"
                            color="iw.cinza2"
                            marginBottom="10"
                            fontWeight="400">{t("partnershipsPage.secondParagraph")}</Text>

                        {(t('partnershipsPage.opportunities', { returnObjects: true }) as DefaultTFuncReturn[]).map((opp: any) => (
                            <Box marginBottom="32px" flexDir="row">
                                <Text
                                    maxWidth={{
                                        base: "100%",
                                        xl: "100%"
                                    }}
                                    fontSize={{
                                        base: "20px",
                                        xl: "26px"
                                    }}
                                    lineHeight="32px"
                                    fontFamily="Lato_400Regular"
                                    color="iw.color7"
                                    fontWeight="700">
                                    {opp?.split("—")[0]}
                                    <Text
                                        maxWidth={{
                                            base: "100%",
                                            xl: "100%"
                                        }}
                                        fontSize={{
                                            base: "20px",
                                            xl: "26px"
                                        }}
                                        lineHeight="32px"
                                        fontFamily="Lato_400Regular"
                                        color="iw.cinza2"
                                        fontWeight="400"> - {opp?.split("—")[1]}</Text></Text>
                            </Box>
                        ))}

                        <Box flexDir="row">
                            <QuotesIcon size={isMobile ? 25 : 60} />
                            <Text
                                flex={1}
                                maxWidth="100%"
                                fontSize={{
                                    base: "20px",
                                    xl: "26px"
                                }}
                                textAlign="end"
                                lineHeight="32px"
                                fontFamily="Lato_400Regular"
                                color="black"
                                italic
                                fontWeight="400">{t('partnershipsPage.foundersQuote')}</Text>
                        </Box>

                        <Box flex="1" marginLeft={10} marginBottom="32px">
                            <Text
                                maxWidth={{
                                    base: "100%",
                                    xl: "100%"
                                }}
                                fontSize={{
                                    base: "20px",
                                    xl: "26px"
                                }}
                                textAlign="end"
                                lineHeight="32px"
                                fontFamily="Lato_400Regular"
                                color="iw.color7"
                                italic
                                fontWeight="400">{t('partnershipsPage.interchangingWorldFounder')}</Text>
                        </Box>

                        <Box marginTop="68px">
                            <Text maxWidth="100%" fontSize={{ base: "23px", xl: "29px" }} lineHeight="33px" fontFamily="Lato_400Regular" color="iw.color7" fontWeight="700">
                                {t("partnershipsPage.contactInfo.contactUsDirectly")}
                            </Text>

                            {getObjectKeys((t('partnershipsPage.contactInfo', { returnObjects: true }) as DefaultTFuncReturn[]), "item").map((key: string) => (
                                <Text maxWidth="100%" fontSize={{ base: "22px", xl: "28px" }} lineHeight="33px" fontFamily="Lato_400Regular" color="iw.color7">
                                    {t(`contactUsPage.contactInfo.${key}`)}
                                </Text>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
};

export default PartnershipsPage;