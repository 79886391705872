import axios from "../apis/iwApi";

export class IwSubscriberService {
    public async addSubscriber(subscriber: any): Promise<any> {
        return axios.post("/subscribers/create", subscriber);
    }

    public async getPotentialPartners() {
        return axios.get("/potentialPartners/")
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return error;
            });
    }
}