import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicyPage = () => {
    return (
        <div className="iw-privacy-policy-content">
            <h1 className='iw-title'>Privacy Policy</h1>

            <div id="terms-container" className="iw-privacy-policy-section">
                <iframe src="https://www.termsfeed.com/live/d9ee5ec4-632c-44c7-b90b-1c0978fc41de" />
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;