import React, { forwardRef, FunctionComponent } from "react";
import { SectionProps } from "./SectionProps";
import "./Section.css";

const Section = forwardRef<HTMLInputElement, SectionProps>((props, ref: any) => {
    return (
        <div
            {...props}
            className={`iw-section ${props.className}`}
            ref={ref}
        />
    );
});

Section.defaultProps = {
    className: ""
};

export default Section;