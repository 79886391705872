import React, { FunctionComponent } from 'react';
import { IIconProps } from './IconProps';

const ChevronLeftIcon: FunctionComponent<IIconProps> = (props) => {
    const { size, color } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 55 96"
            width={size}
            height={size}
            fill={color}
        >
            <path d="M43.625 93.7349L1.5 51.7349C0.999998 51.2349 0.646665 50.6932 0.439999 50.1099C0.229999 49.5265 0.125 48.9015 0.125 48.2349C0.125 47.5682 0.229999 46.9432 0.439999 46.3599C0.646665 45.7765 0.999998 45.2349 1.5 44.7349L43.625 2.60986C44.7917 1.4432 46.25 0.859863 48 0.859863C49.75 0.859863 51.25 1.48486 52.5 2.73486C53.75 3.98486 54.375 5.4432 54.375 7.10986C54.375 8.77653 53.75 10.2349 52.5 11.4849L15.75 48.2349L52.5 84.9849C53.6667 86.1515 54.25 87.5882 54.25 89.2949C54.25 91.0049 53.625 92.4849 52.375 93.7349C51.125 94.9849 49.6667 95.6099 48 95.6099C46.3333 95.6099 44.875 94.9849 43.625 93.7349Z" fill="#3C3C3C" />
        </svg>
    );
};

ChevronLeftIcon.defaultProps = {
    size: 18,
    color: "none"
};

export default ChevronLeftIcon;