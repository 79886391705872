import { configureStore } from '@reduxjs/toolkit';
import generalReducer from "./general/generalSlice";

export const Store = configureStore({
    reducer: {
        generalState: generalReducer,
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof Store.dispatch;
export default Store;