import React, { FunctionComponent } from "react";
import { ExternalLinkProps } from "./ExternalLinkProps";
import "./ExternalLink.css";

const ExternalLink: FunctionComponent<ExternalLinkProps> = (props) => {
    return (
        <a
            {...props}
            className={`iw-external-link ${props.className}`}
        />
    );
};

ExternalLink.defaultProps = {
    className: ""
};

export default ExternalLink;