import { Box, Divider, Hidden, Icon, IconButton, Image, useMediaQuery } from "native-base";
import React, { FunctionComponent } from "react";
import { MaterialIcons } from '@expo/vector-icons';
import { Ionicons } from '@expo/vector-icons';
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setOpenDrawer } from "../../store/general/generalSlice";
import CustomButton from "../Button/CustomButton";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useScrollDirection } from "../../hooks/useScrollDirection";

const Header: FunctionComponent<{}> = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const drawerIsOpen = useAppSelector((state) => state.generalState.openDrawer);
    const scrollDirection = useScrollDirection();

    const queries = [{ maxWidth: 1280 }];
    const [isMobile] = useMediaQuery(queries);

    const dispatch = useAppDispatch();

    const getTopValue = () => {
        if (isMobile && scrollDirection === "down") {
            return -100;
        }
        return 0;
    };

    return (
        <Box
            position="fixed"
            zIndex="10"
            h="100"
            width="full"
            bg="white"
            justifyContent="space-between"
            alignItems="center"
            flexDir="row"
            px={{
                base: "6",
                xl: "40"
            }}
            shadow={4}
            top={getTopValue()}
        >
            <Box w="25%" alignItems="flex-start">
                <IconButton
                    size="lg"
                    icon={<Icon as={MaterialIcons} name="menu" color="iw.color1" size="2xl" />}
                    rounded="lg"
                    onPress={() => dispatch(setOpenDrawer(!drawerIsOpen))}
                />
            </Box>


            <Box alignItems="center" justifyContent="center">
                <Image
                    source={require('../../../src/assets/images/Logo.png')}
                    alt="Interchanging World logo"
                    size="lg"
                    resizeMode="contain"
                />
            </Box>

            <Box
                w="30%"
                flexDir="row"
                alignItems="center"
                justifyContent="flex-end"
                h="58"
                py="3"
            >
                {/* <Hidden only={["base"]}>
                    <Divider orientation="vertical" bg="iw.color4" thickness={2} mr={4} />
                </Hidden>

                <CustomButton
                    style={{ marginRight: "16px" }}
                    onClick={() => navigate("/contact-us")}
                    className='secondary'>
                    {t("applyButton")}</CustomButton> */}

                {/* <Hidden only={["base"]}>
                    <IconButton
                        size="lg"
                        icon={<Icon as={Ionicons} name="person" color="iw.color1" size="2xl" />}
                        rounded="lg"
                        onPress={() => alert("Open menu")}
                    />
                </Hidden> */}
            </Box>
        </Box>
    );
};

export default Header;