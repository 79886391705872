import React, { FunctionComponent } from 'react';
import { IIconProps } from './IconProps';

const ChevronRightIcon: FunctionComponent<IIconProps> = (props) => {
    const { size, color } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 55 96"
            width={size}
            height={size}
            fill={color}
        >
            <path d="M11.375 2.73486L53.5 44.7349C54 45.2349 54.3533 45.7765 54.56 46.3599C54.77 46.9432 54.875 47.5682 54.875 48.2349C54.875 48.9015 54.77 49.5265 54.56 50.1099C54.3533 50.6932 54 51.2349 53.5 51.7349L11.375 93.8599C10.2083 95.0265 8.75 95.6099 7 95.6099C5.25 95.6099 3.75 94.9849 2.5 93.7349C1.25 92.4849 0.625004 91.0265 0.625004 89.3599C0.625005 87.6932 1.25 86.2349 2.5 84.9849L39.25 48.2349L2.50001 11.4849C1.33334 10.3182 0.750008 8.88153 0.750008 7.17486C0.750008 5.46485 1.37501 3.98486 2.62501 2.73486C3.87501 1.48486 5.33334 0.859859 7.00001 0.859859C8.66668 0.859859 10.125 1.48486 11.375 2.73486Z" fill="#3C3C3C" />
        </svg>
    );
};

ChevronRightIcon.defaultProps = {
    size: 18,
    color: "none"
};

export default ChevronRightIcon;