import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Languages imports
import enUSJson from "./translations/en-US.json";
import ptBRJson from "./translations/pt-BR.json";

i18n.use(initReactI18next).init({
    fallbackLng: "en-US",
    interpolation: {
        escapeValue: false
    },
    resources: {
        "en-US": enUSJson,
        "pt-BR": ptBRJson
    }
});

export default i18n;