import React from "react";
import { Box, Heading } from "native-base";
import { useRouteError, isRouteErrorResponse } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
    const { t } = useTranslation();
    const error = useRouteError();
    console.error(error);

    return (
        <Box h="100vh" mt="-16" justifyContent="center" alignItems="center">
            <Heading>Oops! Sorry, an unexpected error has occurred.</Heading>
            {isRouteErrorResponse(error) && <Heading>Error: {error.status} - {t("errors.error404NotFound")}</Heading>}
        </Box>
    );
};

export default NotFoundPage;