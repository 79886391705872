import React, { FunctionComponent } from 'react';
import { IIconProps } from './IconProps';

const SupportIcon: FunctionComponent<IIconProps> = (props) => {
    const { size, color } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 96 96"
            width={size}
            height={size}
            fill={color}
        >
            <path d="M48 8C25.944 8 8 25.944 8 48V64.572C8 68.668 11.588 72 16 72H20C21.0609 72 22.0783 71.5786 22.8284 70.8284C23.5786 70.0783 24 69.0609 24 68V47.428C24 46.3671 23.5786 45.3497 22.8284 44.5996C22.0783 43.8494 21.0609 43.428 20 43.428H16.368C18.592 27.948 31.912 16 48 16C64.088 16 77.408 27.948 79.632 43.428H76C74.9391 43.428 73.9217 43.8494 73.1716 44.5996C72.4214 45.3497 72 46.3671 72 47.428V72C72 76.412 68.412 80 64 80H56V76H40V88H64C72.824 88 80 80.824 80 72C84.412 72 88 68.668 88 64.572V48C88 25.944 70.056 8 48 8Z" fill="url(#paint0_linear_1_444)" />
            <defs>
                <linearGradient id="paint0_linear_1_444" x1="47.9998" y1="7.73547" x2="47.9998" y2="87.8931" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2B587A" />
                    <stop offset="1" stopColor="#13BA9A" />
                </linearGradient>
            </defs>
        </svg>
    );
};

SupportIcon.defaultProps = {
    size: 18,
    color: "none"
};

export default SupportIcon;
