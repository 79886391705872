import React, { FunctionComponent, useEffect } from "react";
import { FAQProps } from "./FAQProps";
import "./FAQ.css";
import DropdownArrowDownIcon from "../Icons/DropdownArrowDownIcon";
import DropdownArrowUpIcon from "../Icons/DropdownArrowUpIcon";

const FAQ: FunctionComponent<FAQProps> = (props) => {
    const { isOpen, question, answer, onClick } = props;

    return (
        <div className={`iw-accordion ${isOpen ? 'opened' : ""}`}>
            <div onClick={onClick} className={`iw-accordion-header ${props.isOpen ? 'opened' : ""}`}>
                <span className="iw-accordion-title">{question}</span>
                {isOpen === true ? <DropdownArrowUpIcon size={16} /> : <DropdownArrowDownIcon size={16} />}
            </div>
            {isOpen === true && (
                <div className="iw-accordion-body">
                    <p>{answer}</p>
                </div>
            )}
        </div>
    );
};

FAQ.defaultProps = {
    isOpen: false
};

export default FAQ;