import React, { FunctionComponent } from "react";
import { RouterLinkProps } from "./RouterLinkProps";
import "./RouterLink.css";
import { Link } from "react-router-dom";

const RouterLink: FunctionComponent<RouterLinkProps> = (props) => {
    return (
        <Link
            {...props}
            className={`iw-router-link ${props.className}`}
        />
    );
};

RouterLink.defaultProps = {
    to: "/",
    className: ""
};

export default RouterLink;