import React, { FunctionComponent } from 'react';
import { IIconProps } from './IconProps';

const SuccessIcon: FunctionComponent<IIconProps> = (props) => {
    const { size, color } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 96 96"
            width={size}
            height={size}
            fill={color}
        >
            <mask id="mask0_1_435" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x="7" y="6" width="82" height="84">
                <path d="M48 8L58.506 15.664L71.512 15.64L75.506 28.016L86.042 35.64L82 48L86.042 60.36L75.506 67.984L71.512 80.36L58.506 80.336L48 88L37.494 80.336L24.488 80.36L20.494 67.984L9.95801 60.36L14 48L9.95801 35.64L20.494 28.016L24.488 15.64L37.494 15.664L48 8Z" fill="white" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M34 48L44 58L64 38" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            </mask>
            <g mask="url(#mask0_1_435)">
                <path d="M0 0H96V96H0V0Z" fill="url(#paint0_linear_1_435)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_1_435" x1="47.9998" y1="-0.317435" x2="47.9997" y2="95.8717" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2B587A" />
                    <stop offset="1" stopColor="#13BA9A" />
                </linearGradient>
            </defs>
        </svg>
    );
};

SuccessIcon.defaultProps = {
    size: 18,
    color: "none"
};

export default SuccessIcon;
