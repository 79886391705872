import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Box, Heading, Image, Text, Link, Button, useMediaQuery } from "native-base";
import { useTranslation } from 'react-i18next';
import { LanguageOption } from '../../../src/interfaces/LanguageOption.interface';
import "../../../src/i18n/i18n.config";
import '../../../src/assets/fonts/KaushanScript-Regular.ttf';
import "../../../App.css";
import StepByStepCard from '../../../src/components/StepByStepCard/StepByStepCard';
import { IwSubscriberService } from '../../services/IwSubscriberService';
import { Subscriber } from '../../interfaces/Subscriber';
import { useNavigate } from 'react-router-dom';
const iwVideo = require("../../../src/assets/videos/IWTrailer.mp4");
import { toast } from 'react-toastify';

import "./HomePage.css";
import SectionHeader from '../../components/Section/SectionHeader';
import WorldGlobeIcon from '../../components/Icons/WorldGlobeIcon';
import CulturalIcon from '../../components/Icons/CulturalIcon';
import AccountGroupIcon from '../../components/Icons/AccountGroupIcon';
import DegreeHatIcon from '../../components/Icons/DegreeHatIcon';
import GoalIcon from '../../components/Icons/GoalIcon';
import SupportIcon from '../../components/Icons/SupportIcon';
import SuccessIcon from '../../components/Icons/SuccessIcon';
import SportIcon from '../../components/Icons/SportIcon';
import WhyUsCard from '../../components/WhyUsCard/WhyUsCard';
import MultiItemCarousel from '../../components/Carousel/MultiItemCarousel';
import Section from '../../components/Section/Section';
import CustomButton from '../../components/Button/CustomButton';
import CustomInput from '../../components/Input/CustomInput';
import CustomLabel from '../../components/Label/CustomLabel';
import CustomFormControl from '../../components/Form/CustomFormControl';
import CustomTextArea from '../../components/Input/CustomTextArea';
import { useAppOutletContext } from '../../routes/root';
import { CountryStateCityService } from '../../services/CountryStateCityService';
import CustomSelect from '../../components/Select/CustomSelect';
import { Country } from '../../interfaces/Country';
import { City } from '../../interfaces/City';
import { StateProvince } from '../../interfaces/StateProvince';
import Modal from '../../components/Modal/Modal';
import { Step } from './IStepProps';
import StepDetailsModal from './StepDetailsModal';
import FAQ from '../../components/FAQ/FAQ';
import { BASE_URL } from '../../apis/iwApi';

const HomePage = () => {
    const [isMobile, xs] = useMediaQuery([{
        maxWidth: 1280
    }, {
        maxWidth: 320
    }]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [email, setEmail] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [countries, setCountries] = useState<Country[]>([]);
    const [country, setCountry] = useState<Country | null>(null);
    const [states, setStates] = useState<StateProvince[]>([]);
    const [state, setState] = useState<StateProvince | null>();
    const [city, setCity] = useState<City | null>();
    const [cities, setCities] = useState<City[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [selectedStep, setSelectedStep] = useState<Step | null>();
    const [faqIsOpen, setFaqIsOpen] = useState<boolean>(false);
    const [isActivePanel, setActivePanel] = useState<Record<number, boolean>>({});

    const video = React.useRef<any>(null);

    const steps: Step[] = [
        {
            stepNumber: 1,
            stepTitle: t("stepByStepTopics.step1Title"),
            stepDescription: t("stepByStepTopics.step1Description"),
            stepDetailedDescription: t('stepByStepTopics.step1DetailedDescription', { returnObjects: true })
        },
        {
            stepNumber: 2,
            stepTitle: t("stepByStepTopics.step2Title"),
            stepDescription: t("stepByStepTopics.step2Description"),
            stepDetailedDescription: t("stepByStepTopics.step2DetailedDescription", { returnObjects: true })
        },
        {
            stepNumber: 3,
            stepTitle: t("stepByStepTopics.step3Title"),
            stepDescription: t("stepByStepTopics.step3Description"),
            stepDetailedDescription: t("stepByStepTopics.step3DetailedDescription", { returnObjects: true })
        },
        {
            stepNumber: 4,
            stepTitle: t("stepByStepTopics.step4Title"),
            stepDescription: t("stepByStepTopics.step4Description"),
            stepDetailedDescription: t("stepByStepTopics.step4DetailedDescription", { returnObjects: true })
        }
    ];

    const { whyUsSectionRef, stepByStepSectionRef, sectionsRef } = useAppOutletContext();

    useImperativeHandle(sectionsRef, () => ({
        get whyUsSection() {
            return whyUsSectionRef;
        },
        get testStepbyStepSection() {
            return stepByStepSectionRef;
        }
    }));

    const subscriberService = new IwSubscriberService();
    const countryStateCityService = new CountryStateCityService();

    const languageOptions: LanguageOption[] = [
        {
            name: "Português (BR)",
            key: "pt-BR"
        },
        {
            name: "English (US)",
            key: "en-US"
        }
    ];

    const handleAddSubscriber = () => {
        setLoading(true);
        let subscriber: Subscriber = {
            email,
            name,
            phone,
            message,
            country: country!.name,
            state: state!.name,
            city: city!.name
        };

        subscriberService.addSubscriber(subscriber)
            .then((response) => {
                handleResetFormFields();
                toast.success(t("contactUsPage.successes.addSubscriber"), { theme: "colored" });
                setLoading(false);
            })
            .catch((error) => {
                if (error?.response?.data?.code) {
                    toast.error(t(`contactUsPage.errors.${error.response.data.code}`), { theme: "colored" });
                }
                else {
                    toast.error(t("contactUsPage.errors.addSubscriber"), { theme: "colored" });
                }
                setLoading(false);
            });
    };

    const handleSubmitEnable = (): boolean => {
        return email && name && phone && country && state && city && !loading ? true : false;
    };

    const handleResetFormFields = () => {
        setEmail("");
        setName("");
        setPhone("");
        setMessage("");
        setCountry(null);
        setState(null);
        setCity(null);
        setStates([]);
        setCities([]);
    };

    useEffect(() => {
        countryStateCityService.getAllCountries()
            .then((response) => {
                setCountries(response.data);
            })
            .catch((error) => console.error("ERROR => ", + error));
    }, []);

    const handleCountryChange = (selectedCountry: Country) => {
        setCountry(selectedCountry);
        setState(null);
        setStates([]);
        setCity(null);
        setCities([]);

        countryStateCityService.getStatesByCountry(selectedCountry.iso2)
            .then((response) => {
                setStates(response.data);
            })
            .catch((error) => console.error("ERROR => ", + error));
    };

    const handleStateChange = (state: StateProvince) => {
        setState(state);
        setCity(null);
        setCities([]);
        if (state && country) {
            countryStateCityService.getCitiesByStateAndCountry(state.iso2, country.iso2)
                .then((response) => {
                    setCities(response.data);
                })
                .catch((error) => console.error("ERROR => ", + error));
        }
    };

    const handleModalOpen = (step: Step) => {
        setSelectedStep(step);
        setModalIsOpen(true);
    };

    const handleModalClose = () => {
        setModalIsOpen(false);
    };

    const toggleItem = (index: number) => {
        setActivePanel(prevState => {
            return ({ ...prevState, [index]: !prevState[index] });
        });
    };

    const onVideoEnded = (event: any) => {
        const myVideo = event.target;
        myVideo.currentTime = 0;
        myVideo.play();
    };

    const [isPortrait, isExtraSmall] = useMediaQuery([{
        orientation: "portrait"
    }, {
        maxWidth: 375
    }]);

    return (
        <>
            <Box
                bg="darkBlue.800"
                height={{
                    base: isExtraSmall ? isPortrait ? "23vh" : "80vh" : isPortrait ? "21.5vh" : "80vh",
                    sm: isExtraSmall ? isPortrait ? "23vh" : "80vh" : isPortrait ? "21.5vh" : "80vh",
                    md: isPortrait ? "21.5vh" : "80vh",
                    lg: isPortrait ? "21.5vh" : "80vh",
                    xl: "68vh",
                }}
                alignItems="center"
                justifyContent="flex-start"
                width="100%"
                mt="100"
                borderBottomWidth="5px"
                borderBottomColor="#67aac1"
            >
                <video
                    id='iw_home_video'
                    onEnded={onVideoEnded}
                    height="100%"
                    playsInline
                    autoPlay
                    muted
                    width="100%"
                    style={{
                        objectFit: "fill"
                    }}
                >
                    <source src={iwVideo} type="video/mp4" />
                    Sorry, your browser doesn't support videos.
                </video>

                {/* <Box
          w={{ base: "80%" }}
          h={{ base: "container" }}
          alignItems="center"
          justifyContent="center"
        >
          <Image
            source={require('./src/assets/images/InterchangingWorldLogo.png')}
            alt="Alternate Text"
            size="xl"
          />
          <Heading color="white">{t("welcome")}</Heading>
        </Box>

        <Select
          bg="white"
          selectedValue={i18n.language}
          minWidth="200"
          placeholder={t("chooseALanguage").toString()}
          _selectedItem={{
            bg: "teal.600",
            endIcon: <CheckIcon size="5" />
          }}
          mt={1}
          onValueChange={itemValue => i18n.changeLanguage(itemValue)}
        >
          {languageOptions.map((option) => (
            <Select.Item label={option.name} value={option.key} />
          ))}
        </Select> */}
            </Box>

            {/* Start Section Welcome to Interchanging World */}
            <Box bg="iw.cinza4" paddingTop="12" paddingBottom="4" alignItems="center" justifyContent="center">
                <Heading
                    fontSize={{
                        base: "16",
                        sm: "20",
                        xl: "22"
                    }}
                    fontWeight="400"
                    fontFamily="Lato_400Regular"
                    lineHeight={32}
                    textTransform={'uppercase'}
                    color="iw.black">{t("welcome")}</Heading>
                <h1 className='iw-title'>INTERCHANGING WORLD</h1>

                <Heading
                    fontSize={{
                        base: "16",
                        sm: "20",
                        xl: "22"
                    }}
                    fontWeight="400"
                    fontFamily="Lato_400Regular"
                    lineHeight={32}
                    textTransform={'uppercase'}
                    mb={4}
                    color="iw.black">{t("entranceExam")}</Heading>

                <Text
                    maxWidth={{
                        base: "80%",
                        xl: "65%"
                    }}
                    fontFamily="Lato_400Regular"
                    textAlign="center"
                    fontSize={{
                        base: "16px",
                        sm: "20",
                        xl: "18px"
                    }}
                    textTransform={"uppercase"}
                    fontWeight="400">{t("welcomeInfo")}</Text>

                <CustomButton
                    style={{ marginTop: '16px' }}
                    onClick={() => navigate("/about-us")}
                    className='primary'>
                    {t("learnMore")}</CustomButton>
            </Box>
            {/* End Section Welcome to Interchanging World */}

            {/* Start Section Step by step */}
            <Section ref={stepByStepSectionRef}>
                <Box width="80%">
                    <SectionHeader title={t("stepByStep").toString()} />
                </Box>

                <Box
                    mt="5"
                    flexDir={{
                        base: "column",
                        xl: "row"
                    }}
                    alignItems={{
                        base: "center",
                        xl: ""
                    }}
                    justifyContent={{
                        base: "flex-start",
                        xl: "space-between"
                    }}
                    maxWidth={{
                        base: "90%",
                        xl: "82%"
                    }}
                    pb="12"
                >
                    {steps.map((step, index) => (
                        <StepByStepCard
                            key={index}
                            stepNumber={step.stepNumber}
                            stepTitle={step.stepTitle}
                            stepDescription={step.stepDescription}
                            stepIcon={
                                <CustomButton
                                    style={{ marginTop: '32px', marginBottom: "48px" }}
                                    onClick={() => handleModalOpen(step)}
                                    className='tertiary'>
                                    {t("learnMore")}</CustomButton>
                            }
                        />
                    ))}
                </Box>
            </Section>
            {/* End Section Step by step */}

            {/* Start Section Why Us? */}
            <Box ref={whyUsSectionRef} px={{ base: "24px", xl: 0 }} py="0" alignItems="center" justifyContent="center">
                <Box width={{ base: "100%", xl: "80%" }}>
                    <SectionHeader title={t("whyUs?").toString()} />
                </Box>

                <Box
                    flexDir={{
                        base: "column",
                        xl: "row"
                    }}
                    // justifyContent={{
                    //     base: "flex-start",
                    //     xl: "space-between"
                    // }}
                    maxWidth={{
                        base: "100%",
                        xl: "82%"
                    }}
                    style={{
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}
                >
                    <MultiItemCarousel show={isMobile ? 1 : 4} infiniteLoop={true}>
                        <div>
                            <div style={{ padding: 8 }}>
                                <WhyUsCard cardIcon={<AccountGroupIcon size={isMobile ? 60 : 90} />} cardDescription={t("whyUsTopics.topic1").toString()} />
                            </div>
                        </div>

                        <div>
                            <div style={{ padding: 8 }}>
                                <WhyUsCard cardIcon={<WorldGlobeIcon size={isMobile ? 60 : 90} />} cardDescription={t("whyUsTopics.topic2").toString()} />
                            </div>
                        </div>

                        <div>
                            <div style={{ padding: 8 }}>
                                <WhyUsCard cardIcon={<CulturalIcon size={isMobile ? 60 : 90} />} cardDescription={t("whyUsTopics.topic3").toString()} />
                            </div>
                        </div>

                        <div>
                            <div style={{ padding: 8 }}>
                                <WhyUsCard cardIcon={<DegreeHatIcon size={isMobile ? 60 : 90} />} cardDescription={t("whyUsTopics.topic4").toString()} />
                            </div>
                        </div>

                        <div>
                            <div style={{ padding: 8 }}>
                                <WhyUsCard cardIcon={<SportIcon size={isMobile ? 60 : 90} />} cardDescription={t("whyUsTopics.topic5").toString()} />
                            </div>
                        </div>
                        <div>
                            <div style={{ padding: 8 }}>
                                <WhyUsCard cardIcon={<SupportIcon size={isMobile ? 60 : 90} />} cardDescription={t("whyUsTopics.topic6").toString()} />
                            </div>
                        </div>
                        <div>
                            <div style={{ padding: 8 }}>
                                <WhyUsCard cardIcon={<GoalIcon size={isMobile ? 60 : 90} />} cardDescription={t("whyUsTopics.topic7").toString()} />
                            </div>
                        </div>
                        <div>
                            <div style={{ padding: 8 }}>
                                <WhyUsCard cardIcon={<SuccessIcon size={isMobile ? 60 : 90} />} cardDescription={t("whyUsTopics.topic8").toString()} />
                            </div>
                        </div>
                    </MultiItemCarousel>
                </Box>
            </Box>;
            {/* End Section Why Us? */}

            {/* Start Section Contact Us */}
            {/* <Section>
                <Box width="80%">
                    <SectionHeader headerContainerprops={{ className: xs ? "fs-24" : "fs-32" }} title={t("wantToKnowMore?").toString()} />
                    <Text fontFamily="Lato_400Regular" color="iw.cinza1" fontSize={{ base: 20, xl: "24" }}>{t("contactUsPage.firstParagraph")}</Text>
                    <Text fontWeight="700" fontFamily="Lato_400Regular" mb="5" color="iw.cinza1" fontSize={{ base: 20, xl: "24" }}>{t("contactUsPage.secondParagraph")}</Text>
                </Box>

                <Box
                    flexDir={{
                        base: "column",
                        xl: "row"
                    }}
                    alignItems={{
                        base: 'center',
                        xl: "flex-start"
                    }}
                    justifyContent="space-between"
                    w={{
                        base: "90%",
                        xl: "80%"
                    }}
                    px={{
                        base: 0,
                        xl: 24
                    }}
                >
                    <Box
                        h={{
                            xl: "100%"
                        }}
                        w={{
                            base: "80%",
                            xl: "60%"
                        }}
                        marginRight={{
                            base: "0",
                            xl: "31px"
                        }}
                        alignItems={{
                            base: "center",
                            xl: "flex-start"
                        }}
                        justifyContent="center"
                    >
                        <Image
                            h={!isPortrait ? "80vh" : "400"}
                            w={"95%"}
                            resizeMode="contain"
                            source={isMobile ? require('../../../src/assets/images/iw-rising-giants-ebook-mobile.png') : require('../../../src/assets/images/iw-rising-giants-ebook.png')}
                            alt="Rising Giants Ebook"
                        />
                    </Box>

                    <Box
                        mt={{
                            base: "5",
                            xl: "0"
                        }}
                        ml={{
                            base: "0",
                            xl: "10"
                        }}
                        w={{
                            base: "90%",
                            xl: "40%"
                        }}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <CustomFormControl>
                            <CustomLabel>{t("nameField")}</CustomLabel>
                            <CustomInput value={name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)} />
                        </CustomFormControl>

                        <CustomFormControl>
                            <CustomLabel>{t("emailField")}</CustomLabel>
                            <CustomInput value={email} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)} />
                        </CustomFormControl>

                        <CustomFormControl>
                            <CustomLabel>{t("phoneField")}</CustomLabel>
                            <CustomInput value={phone} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhone(event.target.value)} />
                        </CustomFormControl>

                        <CustomFormControl>
                            <CustomLabel>{t("countryField")}</CustomLabel>
                            <CustomSelect
                                config={{
                                    label: "name",
                                    key: "id",
                                }}
                                value={country}
                                label={t("select")}
                                options={countries}
                                disabled={countries.length > 0 ? false : true}
                                onChange={(selectedValue: Country) => handleCountryChange(selectedValue)}
                            />
                        </CustomFormControl>

                        <Box display="flex" flexDir={{ base: "column", xl: "row" }} justifyContent={{ base: "center", xl: "space-between" }} width="100%">
                            <CustomFormControl style={{ width: isMobile ? "100%" : "48%" }}>
                                <CustomLabel>{t("stateField")}</CustomLabel>
                                <CustomSelect
                                    config={{
                                        label: "name",
                                        key: "id",
                                    }}
                                    value={state}
                                    label={t("select")}
                                    options={states}
                                    disabled={states.length > 0 ? false : true}
                                    onChange={(selectedValue: StateProvince) => handleStateChange(selectedValue)}
                                />
                            </CustomFormControl>

                            <CustomFormControl style={{ width: isMobile ? "100%" : "48%" }}>
                                <CustomLabel>{t("cityField")}</CustomLabel>
                                <CustomSelect
                                    config={{
                                        label: "name",
                                        key: "id",
                                    }}
                                    value={city}
                                    label={t("select")}
                                    options={cities}
                                    disabled={cities.length > 0 ? false : true}
                                    onChange={(selectedValue: City) => setCity(selectedValue)}
                                />
                            </CustomFormControl>
                        </Box>

                        <CustomFormControl style={{ marginBottom: "12px" }}>
                            <CustomLabel>{t("leaveYourMessage")}</CustomLabel>
                            <CustomTextArea value={message} onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(event.target.value)} />
                        </CustomFormControl>

                        <CustomButton
                            style={{ marginBottom: '30px' }}
                            disabled={!handleSubmitEnable()}
                            onClick={handleAddSubscriber}
                            className='tertiary'
                        >
                            {loading ? t("formSubmitting") : t("formSubmitButton")}
                        </CustomButton>
                    </Box>
                </Box>
            </Section> */}
            {/* End Section Contact Us */}

            {/* Start Section FAQ */}
            <Box py="0" alignItems="center" justifyContent="center">
                <Box width="80%">
                    <SectionHeader headerContainerprops={{ className: "fs-32" }} title={t("faqSection.title").toString()} />
                </Box>

                <Box width={{ base: "80%", xl: "70%" }} mt="8">
                    {(t('faqSection.items', { returnObjects: true }) as any[]).map((item, index) => {
                        const checkOpen: boolean = isActivePanel[index];
                        return <FAQ key={`faq-${index}`} isOpen={checkOpen} onClick={() => toggleItem(index)} question={item.question} answer={item.answer} />;
                    })}
                </Box>
            </Box>
            {/* End Section FAQ */}

            {/* Start Section Partnerships */}
            <Section style={{ paddingBottom: isMobile ? "36px" : "72px" }}>
                <Box width="80%">
                    <SectionHeader title={t("partnershipsTitle").toString()} />
                    <Text
                        marginTop={{ base: 6, xl: 0 }}
                        textAlign="center"
                        alignSelf="center"
                        maxWidth={{ base: "80%", xl: "60%" }}
                        color="iw.cinza1"
                        fontSize={{ base: "22", xl: "28" }}
                        fontWeight="700"
                        fontFamily="Lato_400Regular">{t("partnershipsDescription")}</Text>
                    <CustomButton
                        style={{ marginTop: '16px', width: "fit-content", alignSelf: 'center' }}
                        onClick={() => navigate("/partnerships")}
                        className='tertiary'>
                        {t("clickHere")}</CustomButton>
                </Box>
            </Section>

            <Modal isOpen={modalIsOpen} onClose={handleModalClose}>
                {selectedStep !== null && selectedStep !== undefined && (
                    <StepDetailsModal step={selectedStep}></StepDetailsModal>
                )}
            </Modal>
            {/* End Section Partnerships */}
        </>
    );
};

export default HomePage;
