import React, { FunctionComponent, useEffect } from "react";
import { ModalProps } from "./ModalProps";
import "./Modal.css";
import CloseIcon from "../Icons/CloseIcon";
import IconButton from "../Button/IconButton";

const Modal: FunctionComponent<ModalProps> = (props) => {
    const { isOpen, onClose } = props;

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'unset';
        }
    }, [props.isOpen]);

    if (!isOpen) {
        return null;
    }

    return (
        <div className={`iw-modal-backdrop ${isOpen ? 'opened' : ""}`}>
            <div
                {...props}
                className={`iw-modal ${isOpen ? 'opened' : ""}`}
            >
                <div className="header-button-close">
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className="iw-modal-body">
                    {props.children}
                </div>
            </div>
        </div>
    );
};

Modal.defaultProps = {
    isOpen: false
};

export default Modal;