import axios from "axios";

const api = axios.create({
    baseURL: "https://api.countrystatecity.in/v1",
    timeout: 15000
});

api.interceptors.request.use(async (config: any) => {
    config.headers = {
        'X-CSCAPI-KEY': 'OFpFR1NvM1R2VHQyTEthY01VWThaa2J4N2x5cWpIQjgwa2NXUXlPOQ=='
    };
    return config;
});

export default api;