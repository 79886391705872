import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import "./Footer.css";
import Title from "../Title/Title";
import IWCompleteLogo from "../Icons/IWCompleteLogo";
import RouterLink from "../Link/RouterLink";
import ExternalLink from "../Link/ExternalLink";
import { Hidden } from "native-base";

const Footer = forwardRef((props, ref: any) => {
    const { t } = useTranslation();
    return (
        <div className="iw-footer">
            <div className="iw-footer-row w-60">
                <Hidden only={["base", "sm"]}>
                    <div className="iw-footer-column">
                        <Title>{t("footer.meet.title")}</Title>
                        <RouterLink className="sub-22" to="/about-us">{t("footer.meet.aboutUs")}</RouterLink>
                    </div>
                </Hidden>
                <Hidden only={["base", "sm"]}>
                    <div className="iw-footer-column">
                        <Title>{t("footer.letsTalk.title")}</Title>
                        {/* <RouterLink to="/contact-us" className="sub-22">{t("footer.letsTalk.contactUs")}</RouterLink> */}
                        <RouterLink to="/partnerships" className="sub-22">{t("footer.letsTalk.partnerships")}</RouterLink>
                    </div>
                </Hidden>
                <div className="iw-footer-column">
                    <Title>{t("footer.privacy.title")}</Title>
                    <RouterLink to="/terms-of-use" className="sub-22">{t("footer.privacy.termsOfUse")}</RouterLink>
                    <RouterLink to="/privacy-policy" className="sub-22">{t("footer.privacy.privacyPolicy")}</RouterLink>
                </div>
            </div>
            <div className="iw-footer-row w-80">
                <div className="iw-footer-column col-3 justify-end">
                    <Title className="sub-24">{t("footer.copyright")}</Title>
                </div>
                <div className="iw-footer-column col-3" style={{ alignItems: "center" }}>
                    <IWCompleteLogo size={190} />
                </div>
                <div className="iw-footer-column col-3 justify-end">
                    <div className="iw-footer-row">
                        <ExternalLink href="https://www.facebook.com/profile.php?id=100093097202913&mibextid=LQQJ4d" target="_blank" className="sub-22">{t("footer.facebook")}</ExternalLink>
                        <ExternalLink href="https://www.instagram.com/interchangingworld/" target="_blank" className="sub-22">{t("footer.instagram")}</ExternalLink>
                        <ExternalLink href="https://twitter.com/interchangworld?s=21" target="_blank" className="sub-22">{t("footer.twitter")}</ExternalLink>
                        <ExternalLink href="mailto:contact@interchangingworld.com" className="sub-22">{t("footer.email")}</ExternalLink>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Footer;