import React, { useRef } from "react";
import Header from "../components/Headers/Header";
import { Outlet, useOutletContext } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { Box } from "native-base";
import { AppOutletContext } from "./AppOutletContext";
import Drawer from "../components/Drawer/Drawer";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setOpenDrawer } from "../store/general/generalSlice";
import DrawerItem from "../components/Drawer/DrawerItem";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";

const Root = () => {
    const { t } = useTranslation();
    const drawerIsOpen = useAppSelector((state) => state.generalState.openDrawer);
    const whyUsSectionRef = useRef(null);
    const stepByStepSectionRef = useRef(null);
    const sectionsRef = useRef(null);
    const dispatch = useAppDispatch();

    return (
        <Box bg="iw.cinza4">
            <Drawer isOpen={drawerIsOpen} onClose={() => dispatch(setOpenDrawer(false))}>
                <DrawerItem to="/">{t("drawer.labels.home")}</DrawerItem>
                <DrawerItem to="/about-us">{t("drawer.labels.aboutUs")}</DrawerItem>
                {/* <DrawerItem to="/contact-us">{t("drawer.labels.contactUs")}</DrawerItem> */}
                <DrawerItem to="/partnerships">{t("drawer.labels.partnerships")}</DrawerItem>
                {/* <DrawerItem to="/announcement">{t("drawer.labels.announcement")}</DrawerItem> */}
            </Drawer>
            <Header />
            <Outlet context={{ whyUsSectionRef, stepByStepSectionRef, sectionsRef }} />
            <Footer ref={sectionsRef} />
            <ScrollToTop />
        </Box>
    );
};

export default Root;

export function useAppOutletContext() {
    return useOutletContext<AppOutletContext>();
}
