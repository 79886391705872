import React, { FunctionComponent } from "react";
import "./IconButton.css";

const IconButton: FunctionComponent<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> = (props) => {
    return (
        <button {...props} className={`iw-icon-button ${props.className}`}>
            {props.children}
        </button>
    );
};

IconButton.defaultProps = {
    className: ""
};

export default IconButton;