import React, { FunctionComponent } from "react";
import { ISectionHeaderProps } from "../interfaces/SectionHeaderProps";
import "./SectionHeader.css";

const SectionHeader: FunctionComponent<ISectionHeaderProps> = (props) => {
    return (
        <div {...props.headerContainerprops} className={`iw-section-header-container ${props.headerContainerprops?.className}`}>
            <h2 className='iw-section-header-title'>{props.title}</h2>
            <div className="iw-section-header-stripe"></div>
        </div>
    );
};

SectionHeader.defaultProps = {
    title: "Header"
};

export default SectionHeader;