import { createSlice } from '@reduxjs/toolkit';

// Define a type for the slice state
interface GeneralState {
    openDrawer: boolean;
}

// Define the initial state using that type
const initialState: GeneralState = {
    openDrawer: false,
};

export const generalSlice = createSlice({
    name: 'general',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        setOpenDrawer: (state, action) => {
            state.openDrawer = action.payload;
        }
    },
});

export const { setOpenDrawer } = generalSlice.actions;

export default generalSlice.reducer;