import React, { FunctionComponent, useEffect } from "react";
import { DrawerProps } from "./DrawerProps";
import "./Drawer.css";

const Drawer: FunctionComponent<DrawerProps> = (props) => {
    const { isOpen, onClose } = props;

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'unset';
        }
    }, [props.isOpen]);

    return (
        <div onClick={onClose} className={`iw-backdrop ${props.isOpen ? 'opened' : ""}`}>
            <div
                {...props}
                className={`iw-drawer ${props.isOpen ? 'opened' : ""}`}
            >
                {props.children}
            </div>
        </div>
    );
};

Drawer.defaultProps = {
    isOpen: false
};

export default Drawer;