import React from "react";
import "./TermsOfUse.css";

const TermsOfUsePage = () => {
    return (
        <div className="iw-terms-of-use-content">
            <h1 className='iw-title'>Terms of Use</h1>

            <div id="terms-container" className="iw-terms-of-use-section">
                <iframe src="https://www.termsfeed.com/live/4c6b76e7-4be3-4e0a-9b5b-6bcdc00820d1" />
            </div>
        </div>
    );
};

export default TermsOfUsePage;