import { Box, Heading, Text, useMediaQuery } from "native-base";
import React, { FunctionComponent } from "react";
import { StepByStepCardProps } from "../interfaces/StepByStepCardProps";
import "./StepByStepCard.css";

const StepByStepCard: FunctionComponent<StepByStepCardProps> = (props) => {
    const [xs, xxl] = useMediaQuery([
        {
            maxWidth: 320
        }, {
            maxWidth: 1440
        }
    ]);

    return (
        <Box
            m={1}
            mb={{
                base: 5,
                xl: 1
            }}
            width={{
                base: "90%",
                xl: "23.5%"
            }}
            // alignItems="center"
            justifyContent="flex-start"
            minH={{
                base: "20",
                xl: "md"
            }}
            flexDir="column"
            bg="white:alpha.50"
            borderRadius={24}
            shadow="9"
        >
            <Box
                alignItems="center"
                justifyContent="center"
                mb={{
                    base: "3.5",
                    xl: "1"
                }}
                py={{
                    base: xs ? 0 : 6,
                    xl: xxl ? 0 : 12
                }}
            >
                <h1 className='iw-card-title'>{props.stepNumber}</h1>
            </Box>
            <Box
                alignItems="center"
                justifyItems="center"
                flex={1}
                mx={6}
            >
                <Heading fontSize={{
                    base: 30,
                    xl: xxl ? 30 : 40
                }} my="-1" size="lg">{props.stepTitle}</Heading>
                <Text
                    mt={{
                        base: 3,
                        xl: 7
                    }}
                    color="iw.cinza1"
                    textAlign="center"
                    fontFamily="Lato_400Regular"
                    lineHeight="120%"
                    fontSize={{
                        base: xs ? "18" : "22",
                        xl: xxl ? 22 : 28
                    }}>{props.stepDescription}</Text>
            </Box>
            {props.stepIcon && (
                <Box
                    mt={{
                        base: '2',
                        xl: '5'
                    }}
                    alignItems="center"
                    justifyContent="center"
                >
                    {props.stepIcon}
                </Box>
            )}
        </Box>
    );
};

export default StepByStepCard;